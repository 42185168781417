import React from "react";

// Utilities
import kebabCase from "lodash.kebabcase";

// Components
import { graphql } from "gatsby";

import { TagButton } from "../components/styled";
import { Layout, SEO } from "../components";

interface IProps {
  data: {
    allMarkdownRemark: {
      group: Array<{
        fieldValue: string;
        totalCount: number;
      }>;
    };
  };
}

const TagsPage: React.FC<IProps> = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <SEO title="All tags" />
    <div>
      <h1>Tags</h1>
      {group.map((tag) => (
        <TagButton
          to={`/tags/${kebabCase(tag.fieldValue)}/`}
          key={tag.fieldValue}
        >
          {tag.fieldValue} ({tag.totalCount})
        </TagButton>
      ))}
    </div>
  </Layout>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
